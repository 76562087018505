<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Document card</h3>

	<p>From the framework <span v-html="link('treeview', 'tree view')"></span>, click on the text or <v-icon small>fas fa-map</v-icon> icon of the document-level “node” of the tree to see the document’s “card”:</p>
	<p><img alt="Document card image" srcset="/docimages/document_card-1.png 2x" class="k-help-img"></p>
	<ul>
		<li>At the top of the card, you’ll see the document’s title. Click the <v-icon small>fas fa-copy</v-icon> button to copy the title to your clipboard.</li>
		<li>In the upper-right corner, you’ll see a small icon button <v-icon small>fas fa-expand</v-icon> <span v-html="link('expanded_card', 'expand')"></span> the card.</li>
		<li>Below the item, you will see some of the metadata fields for the framework’s document item. The four fields shown in the screenshot above will always be present:<ul>
			<li><b>Status:</b> the current status of the framework. This will usually be “Draft”, “Proposed”, or “Adopted”, with a parenthetical note telling you if the framework is <i>public</i> (available to be viewed by any <span v-html="app_name"/> user, even if they are not signed in) or <i>private</i> (available only to <span v-html="app_name"/> users who are signed in and have rights to view the framework). More help is available for <span v-html="link('adoption_status', 'adoption statuses')"></span>, <span v-html="link('roles_system', 'system-level roles')"></span> and <span v-html="link('roles_framework', 'framework-level roles')"></span>.</li>
			<li><b>Creator:</b> the organization that created, and is responsible for maintaining, the framework.</li>
			<li><b>Globally Unique Identifier:</b> A 32-character (plus hyphens) hexidecimal string that uniquely identifies the framework.</li>
			<li><b>Last Changed:</b> The date when the framework was last changed. (Note that the last-changed date is usually updated for the document when either the document itself <i>or any item in the framework</i> is changed.)</li>
		</ul></li>
		<li>At the bottom of the card, you’ll see buttons that allow you to:<ul>
			<li><v-icon small>fas fa-code</v-icon> <span v-html="link('more_info', 'view ALL DOCUMENT METADATA')"></span> and see a detailed history of archived changes to that document metadata</li>
			<li><v-icon small>fas fa-print</v-icon> <span v-html="link('export_options', 'print')"></span> the document and items in the framework.</li>
		</ul></li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	